import React, { useState, useRef } from 'react';
import styled from 'styled-components/macro';
import { Formik } from 'formik';
import ColorBox from '../wraps/ColorBox/ColorBox';
import Input from '../Input/Input';
import Block from '../wraps/Block/Block';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import ParagraphShort from '../text/ParagraphShort';

const URL =
  process.env.EMAIL_URL ||
  'https://us-central1-strony-8ee5c.cloudfunctions.net/poznanStronyWWW';
const key =
  process.env.RECAPTCHA_SITE_KEY || '6LdeCfUZAAAAAOXiqa14D3R-sYGxkbIm2v-PZuS2';

const SendColorBox = styled(ColorBox)`
  border: none;
  cursor: pointer;
  transition: 0.3s ease;
  color: ${({ theme }) => theme.colors.white};
  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
  }
  &:focus {
    border: none;
  }
`;

const StyledParagraphShort = styled(ParagraphShort)`
  display: ${({ show }) => (show ? 'block' : 'none')};
  margin-bottom: 1.5rem;
`;

const ContactForm = () => {
  const recaptchaRef = useRef(null);

  const [token, setToken] = useState('');
  const [show, setShowInfo] = useState(false);

  const [text, setText] = useState('');

  const hideInfo = () => {
    setShowInfo(false);
  };

  const showInfo = () => {
    setShowInfo(true);
  };

  return (
    <Formik
      initialValues={{ name: '', email: '', message: '' }}
      validate={values => {
        const errors = {};
        if (!values.name) {
          errors.name = 'Podaj imię';
        }
        if (!values.message) {
          errors.message = 'Wpisz treść wiadomości';
        }
        if (!values.email) {
          errors.email = 'Podaj adres email';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Niepoprawny adres email';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        if (token) {
          axios
            .post(URL, { ...values, token })
            .then(() => {
              setText(
                'Dziękujemy za wiadomość. Odpowiemy najszybciej jak to będzie możliwe.',
              );
              showInfo();

              resetForm({});
              recaptchaRef.current.reset();

              setSubmitting(false);
            })
            .catch(() => {
              setText(
                'Przepraszamy. Nie udało się wysłać wiadomości. Spróbuj ponownie później.',
              );
              showInfo();
              setSubmitting(false);
            });
        } else {
          setText('Prosimy o potwierdzenie czy jesteś człowiekiem.');
          showInfo();
        }
        setSubmitting(false);
        setTimeout(() => {
          hideInfo();
        }, 5000);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <Block width="100%" horizontal>
            <Block width="50%" padding justifyStart>
              <Input
                type="text"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Imię"
                value={values.name}
                errorMessage={errors.name && touched.name && errors.name}
              />
              <Input
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Email"
                value={values.email}
                errorMessage={errors.email && touched.email && errors.email}
              />
            </Block>

            <Block width="50%" padding justifyStart>
              <StyledParagraphShort opacity={0.7} show={show}>
                {text}
              </StyledParagraphShort>
              <Input
                type="text"
                name="message"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Wiadomość"
                value={values.message}
                textarea
                errorMessage={
                  errors.message && touched.message && errors.message
                }
              />
            </Block>
            <ReCAPTCHA
              sitekey={key}
              ref={recaptchaRef}
              onChange={value => {
                setToken(value);
              }}
              style={{ margin: '2rem auto' }}
              size="compact"
            />
            <Block width="50%">
              <SendColorBox as="button" type="submit" disabled={isSubmitting}>
                Wyślij
              </SendColorBox>
            </Block>
          </Block>
        </form>
      )}
    </Formik>
  );
};

export default ContactForm;
