import React, { useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import Section from '../../../shared/wraps/Section/Section';
import GridBlock from '../../../shared/wraps/GridBlock/GridBlock';
import BigTitle from '../../../shared/text/BigTitle';
import MakeBlock from '../../../MakeBlock/MakeBlock';
import ParagraphShort from '../../../shared/text/ParagraphShort';
import icon1 from '../../../../assets/images/icons/icon1.png';
import icon2 from '../../../../assets/images/icons/icon2.png';
import icon3 from '../../../../assets/images/icons/icon3.png';
import icon4 from '../../../../assets/images/icons/icon4.png';
import SiteBackground from '../../../shared/SiteBackground/SiteBackground';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { onScrollAnimation } from '../../../../animations/animations';

const StyledSection = styled(Section)`
  .rightParagraphBlock {
    grid-row: 1/2;
    grid-column: 4/5;
  }
  .makeBlock1,
  .makeBlock2,
  .makeBlock3,
  .makeBlock4 {
    background-color: #fff;
    grid-row: 2/3;
  }

  .makeBlock1 {
    grid-column: 1/2;
  }

  .makeBlock2 {
    grid-column: 2/3;
  }

  .makeBlock3 {
    grid-column: 3/4;
  }

  .makeBlock4 {
    grid-column: 4/5;
  }

  @media screen and (max-width: 991px) {
    .rightParagraphBlock {
      grid-row: 2/3;
      grid-column: 3/4;
    }

    .makeBlock1 {
      grid-column: 1/2;
    }

    .makeBlock2 {
      grid-column: 2/3;
    }

    .makeBlock3 {
      grid-row: 3/4;
      grid-column: 1/2;
    }

    .makeBlock4 {
      grid-row: 3/4;
      grid-column: 2/3;
    }
  }
`;

const StyledBigTitle = styled(BigTitle)`
  margin-top: 15.1rem;
  margin-bottom: 21rem;
  grid-row: 1/2;
  grid-column: 2/4;

  @media screen and (max-width: 991px) {
    grid-column: 1/4;
    margin-bottom: 10rem;
  }

  @media screen and (max-width: 767px) {
    margin-top: 63px;
    margin-bottom: 28px;
  }
`;

const StyledParagraph = styled(ParagraphShort)`
  margin-bottom: 10.9rem;
  @media screen and (max-width: 991px) {
    margin-bottom: 0;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 12px;
    text-align: center;
  }
`;

const Make = () => {
  const wrapper = useRef(null);

  useEffect(() => {
    const section = wrapper.current.base;
    const title = section.querySelector('.bigTitle');

    const runAnimation = () =>
      onScrollAnimation(title, 'makeTitle', section, 'makeSection');
    runAnimation();
    return () => {
      gsap.getById('makeTitle').kill();
      ScrollTrigger.getById('makeSection').kill();
    };
  });

  return (
    <StyledSection ref={wrapper}>
      <SiteBackground bgcAllSite />
      <StyledBigTitle className="bigTitle">make</StyledBigTitle>
      <GridBlock className="rightParagraphBlock" padding>
        <StyledParagraph>
          Zespół grafików, programistów, copywriterów, projekt managerów pełen
          twórczych pasji i pomysłów gotowy do działania.
        </StyledParagraph>
      </GridBlock>
      <MakeBlock
        className="makeBlock1"
        title="Strony internetowe"
        slug="strony-internetowe"
        icon={icon1}
        xl
      >
        Nasze strony są odpowiedzią na Twoje realne potrzeby, które zdefiniujemy
        wspólnie przed rozpoczęciem prac. Panel CMS do obsługi treści pozwoli Ci
        w prosty sposób samodzielnie zarządzać tekstami i zdjęciami.
      </MakeBlock>
      <MakeBlock
        className="makeBlock2"
        title="Sklepy internetowe"
        slug="sklepy-internetowe"
        icon={icon2}
        xl
      >
        Przemyślana architektura i właściwy dobór funkcjonalności sklepu sprawi,
        że spełni on najważniejszą funkcję - będzie narzędziem, które ułatwi
        Tobie pracę i pozwoli rozwijać sprzedaż.
      </MakeBlock>
      <MakeBlock
        className="makeBlock3"
        title="Projektowanie graficzne"
        slug="projektowanie-graficzne"
        icon={icon3}
      >
        Odpowiednie kolory, czcionki i kształty to nie wszystko. Ważnym zadaniem
        grafiki jest przekazanie właściwego komunikatu. Do tego potrzebny jest
        doświadczony zespół i sprawdzone narzędzia - to wszystko znajdziesz w
        Makadu.
      </MakeBlock>
      <MakeBlock
        className="makeBlock4"
        title="Dedykowane aplikacje i systemy"
        slug="dedykowane-aplikacje-i-systemy"
        icon={icon4}
      >
        Zarówno mniejsze aplikacje mobilne jak i rozbudowane systemy klasy CRM,
        ERP powinny być bezpieczne i intuicyjne w obsłudze. Pozostań specjalistą
        w swojej branży, a nam pozwól zbudować narzędzie, które usprawni Twoją
        pracę.
      </MakeBlock>
    </StyledSection>
  );
};

export default Make;
