import React from 'react';
import PropTypes from 'prop-types';
import TransitionLink from 'gatsby-plugin-transition-link';
import { fadeIn, fadeOut } from '../../../../animations/animations';
import styled from 'styled-components';

const StyledTransitionLink = styled(TransitionLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.black};
`;

const FadeLink = ({ children, ...props }) => (
  <StyledTransitionLink
    entry={{
      trigger: ({ entry, node }) => fadeIn(node, entry),
      delay: 0.5,
      length: 0.5,
    }}
    exit={{
      length: 0.5,
      trigger: ({ exit, node }) => fadeOut(node, exit),
    }}
    {...props}
  >
    {children}
  </StyledTransitionLink>
);

FadeLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
};

export default FadeLink;
