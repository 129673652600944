import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Image from 'gatsby-image';
import FadeLink from '../../navigations/FadeLink/FadeLink';
import ParagraphShort from '../../text/ParagraphShort';
import BigTitle from '../../text/BigTitle';
import Content from '../../Content';
import translate from '../../../../utils/translate';
import ParagraphInfo from '../../text/ParagraphInfo';

const BlogSectionWrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @media screen and (max-width: 767px) {
    display: block;
  }
`;

const Wrapper = styled.section`
  width: 100%;
  margin-right: ${({ mr }) => mr && '3rem'};
  padding: 3rem;
  grid-column: ${({ wide }) => wide && '1 / 4'};
  @media screen and (max-width: 767px) {
    padding: 1rem 0;
  }
`;

const StyledBigTitlePost = styled(BigTitle)`
  opacity: 0.7;
  text-align: left;
  line-height: 1;

  ${({ post }) =>
    post &&
    css`
      margin-top: -3rem;
    `};

  @media screen and (max-width: 767px) {
    font-size: 4rem;
  }
`;

const RecentPostsWrapperTitle = styled.h2`
  font-size: 2rem;
  position: relative;
  padding: 0.5rem 0;
  margin-bottom: 2rem;
  ::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.black};
  }
`;

const RecentPostsList = styled.ul`
  list-style: none;
  li:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const RecentPostsTitle = styled.h2`
  font-size: 2rem;
  opacity: 0.7;
  text-transform: lowercase;
`;

const RecentPostsLink = styled(FadeLink)`
  color: ${({ theme }) => theme.colors.black};
  text-decoration: none;
  transition: color 0.2s;
  :hover {
    color: ${({ theme }) => theme.colors.main};
  }
`;

const StyledReadMoreLink = styled(FadeLink)`
  color: ${({ theme }) => theme.colors.black};
  opacity: 0.7;
  text-decoration: none;
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
  position: relative;
  margin-top: 2rem;
  letter-spacing: 0.1rem;
  align-self: flex-end;
  transition: color 0.2s;
  :hover {
    color: ${({ theme }) => theme.colors.main};
  }
`;

const PostItem = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 5rem;
  position: relative;
  ::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1rem;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.black};
  }
`;

const StyledBigTitle = styled(BigTitle)`
  text-align: center;
  width: 100%;
  margin-bottom: 10rem;
`;

const StyledImage = styled(Image)`
  margin: 2rem auto;
`;

const StyledContent = styled(Content)`
  margin: 3rem auto;
`;

const StyledPagination = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  height: 3rem;
  justify-content: center;
`;

const StyledListItem = styled.li`
  &:not(:last-child) {
    margin-right: 0.8rem;
  }
`;

const StyledFadeLink = styled(FadeLink)`
  font-size: 2rem;
  text-decoration: ${({ active }) => active && 'underline'};
  transition: color 0.2s;
  &:hover {
    color: ${({ theme, active }) =>
      active ? theme.colors.black : theme.colors.main};
  }
  ${({ active }) =>
    active &&
    css`
      text-decoration: underline;
      font-size: 2.2rem;
      cursor: default;
    `}
`;

const BlogWrapper = ({
  content,
  postsList,
  post,
  title,
  createdOn,
  preparedCategories,
  numPages,
  currentPage,
}) => (
  <>
    {!post && <StyledBigTitle>BLOG</StyledBigTitle>}

    <BlogSectionWrapper>
      <Wrapper mr wide>
        {!post ? (
          content.map(post => {
            const date = translate(post.created_on);
            return (
              <PostItem key={post.slug}>
                <div>
                  <StyledBigTitlePost small>{post.title}</StyledBigTitlePost>
                  <ParagraphShort>{date}</ParagraphShort>
                  <StyledImage
                    fluid={post.preview.localFile.childImageSharp.fluid}
                    alt={post.title}
                  />
                </div>
                <ParagraphInfo>{post.excerpts}</ParagraphInfo>
                <StyledReadMoreLink to={`/blog/${post.slug}`}>
                  CZYTAJ DALEJ
                </StyledReadMoreLink>
              </PostItem>
            );
          })
        ) : (
          <>
            <StyledBigTitlePost small post>
              {title}
            </StyledBigTitlePost>
            <ParagraphShort>{createdOn}</ParagraphShort>
            <ParagraphShort>{preparedCategories}</ParagraphShort>
            <StyledContent post>{content}</StyledContent>
          </>
        )}
        {!post && (
          <StyledPagination>
            {Array.from({ length: numPages }, (_, i) => (
              <StyledListItem>
                <StyledFadeLink
                  to={i === 0 ? '/blog/' : `/blog/${i + 1}`}
                  active={currentPage === i + 1}
                >
                  {i + 1}
                </StyledFadeLink>
              </StyledListItem>
            ))}
          </StyledPagination>
        )}
      </Wrapper>
      <Wrapper>
        <RecentPostsWrapperTitle>OSTATNIE POSTY</RecentPostsWrapperTitle>
        <RecentPostsList>
          {postsList.map(post => {
            const date = translate(post.created_on);
            return (
              <li>
                <RecentPostsLink to={`/blog/${post.slug}`}>
                  <RecentPostsTitle>{post.title}</RecentPostsTitle>
                </RecentPostsLink>
                <p>{date}</p>
              </li>
            );
          })}
        </RecentPostsList>
      </Wrapper>
    </BlogSectionWrapper>
  </>
);

BlogWrapper.propTypes = {
  post: PropTypes.bool,
};

BlogWrapper.defaultProps = {
  post: false,
};

export default BlogWrapper;
