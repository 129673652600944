import React, { useContext, useEffect } from 'react';
import { MainPageContext } from '../../../../context/MainPageContextProvider';
import styled from 'styled-components';
import { gsap } from 'gsap';
import MakaduLogo from '../../../../assets/svgs/makadu_logo.svg';

const IntroSection = styled.div`
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  visibility: hidden;
`;

const StyledMakaduLogo = styled(MakaduLogo)`
  width: 40rem;
  max-width: 100%;
`;

const Intro = () => {
  const { showIntro, setShowIntro } = useContext(MainPageContext);
  if (showIntro) {
    useEffect(() => {
      const introSection = document.querySelector('.introSection');
      const layout = document.querySelector('.layout');

      gsap.set([layout, introSection], { autoAlpha: 0 });

      const tl = gsap.timeline();
      tl.to(introSection, {
        duration: 1,
        delay: 1,
        autoAlpha: 1,
      })
        .to(introSection, { duration: 1, delay: 1, autoAlpha: 0 })
        .to(layout, {
          duration: 1,
          autoAlpha: 1,
          onComplete: () => setShowIntro(false),
        });
    });
  }

  return (
    <IntroSection className="introSection">
      <StyledMakaduLogo />
    </IntroSection>
  );
};

export default Intro;
