import styled, { css } from 'styled-components';

const Content = styled.article`
  p {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.black};
    margin-bottom: 1rem;
    opacity: 0.7;
    ${({ post }) =>
      post &&
      css`
        margin: 1rem auto;
      `}
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ theme }) => theme.colors.black};
    opacity: 0.7;
  }
  h2,
  h3,
  h4 {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.black};
    text-align: left;
    text-transform: lowercase;
    margin-bottom: 1.6rem;
  }
  h2,
  h3,
  h4 {
    font-size: 2.8rem;
  }
  h5,
  h6 {
    font-size: 2rem;
    margin-bottom: 1.3rem;
  }
  a {
    color: ${({ theme }) => theme.colors.black};
    :hover {
      color: ${({ theme }) => theme.colors.black2};
    }
  }
  ul {
    padding-left: 2rem;
    margin-left: 1rem;
    max-width: 50%;
    margin-bottom: 2rem;
    line-height: 1.3;
    position: relative;
    li {
      font-size: 1.6rem;
      color: ${({ theme }) => theme.colors.black};
      border-radius: 1rem;
      padding: 0.8rem;
      ::marker {
        color: green;
      }
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
  }
  blockquote {
    position: relative;
    padding: 1rem;
    max-width: 70%;
    margin: 4rem auto;
    p {
      max-width: 100%;
      margin-bottom: 0;
    }
    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 80%;
      height: 100%;
    }
    &::before {
      top: -2px;
      left: -2px;
      border-left: 2px solid ${({ theme }) => theme.colors.main};
      border-top: 2px solid ${({ theme }) => theme.colors.main};
    }
    &::after {
      bottom: -2px;
      right: -2px;
      border-right: 2px solid ${({ theme }) => theme.colors.main};
      border-bottom: 2px solid ${({ theme }) => theme.colors.main};
    }
  }

  img {
    max-width: 100%;
    height: auto;
    margin: 2rem auto;
    display: block;
  }
  @media screen and (max-width: 767px) {
    p {
      max-width: unset;
    }
    ul {
      max-width: unset;
      margin-left: 0;
    }
  }
`;

export default Content;
