import React from 'react';
import Layout from '../layouts/Layout';
import Section from '../components/shared/wraps/Section/Section';
import { graphql } from 'gatsby';
import BlogSectionWrapper from '../components/shared/wraps/BlogSectionWrapper/BlogSectionWrapper';

export const blogQuery = graphql`
  query blogQuery($limit: Int!, $skip: Int!) {
    currentPosts: allDirectusPosty(
      sort: { fields: created_on, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        title
        slug
        created_on
        excerpts
        preview {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      }
    }
    postsList: allDirectusPosty(
      sort: { fields: created_on, order: DESC }
      limit: 15
    ) {
      nodes {
        title
        slug
        created_on
        content
      }
    }
  }
`;

const BlogTemplate = ({ data, location: { pathname: path }, pageContext }) => {
  const currentPosts = data.currentPosts.nodes;
  const postsList = data.postsList.nodes;
  const { numPages, currentPage } = pageContext;
  return (
    <Layout path={path} id="blog" nonBgc>
      <Section nonGrid site nonFlex first>
        <BlogSectionWrapper
          content={currentPosts}
          postsList={postsList}
          numPages={numPages}
          currentPage={currentPage}
        />
      </Section>
    </Layout>
  );
};

export default BlogTemplate;
