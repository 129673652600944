import React from 'react';
import BlogSectionWrapper from '../components/shared/wraps/BlogSectionWrapper/BlogSectionWrapper';
import { graphql } from 'gatsby';
import Layout from '../layouts/Layout';
import HtmlParser from 'react-html-parser';
import Section from '../components/shared/wraps/Section/Section';
import translate from '../utils/translate';

export const postQuery = graphql`
  query postsQuery($id: String!) {
    post: directusPosty(id: { eq: $id }) {
      title
      slug
      content
      created_on
      categories
    }
    posts: allDirectusPosty(sort: { fields: created_on, order: DESC }) {
      nodes {
        title
        slug
        created_on
      }
    }
  }
`;

const Post = ({ data, location: { pathname: path } }) => {
  const postsList = data.posts.nodes;
  const { content, title, created_on, categories } = data.post;
  const [, ...preparedCategories] = categories;
  preparedCategories.pop();

  const date = translate(created_on);
  return (
    <Layout path={path} id="post" nonBgc>
      <Section nonGrid site nonFlex first>
        <BlogSectionWrapper
          content={HtmlParser(content)}
          postsList={postsList}
          title={title}
          post
          createdOn={date}
          preparedCategories={preparedCategories.join(', ')}
        />
      </Section>
    </Layout>
  );
};

export default Post;
